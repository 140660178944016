@import "../theme/mixins";

$brand: 'maloa';
$brandAssets: "/assets/#{$brand}";
:root {
  --brand: $brand;
  --ion-color-main: #2F333A;
  --ion-color-second: rgba(47, 51, 58, 0.7);
  --ion-color-desc: #6b6b6b;

}

@font-face {
  font-family: AppFont;
  src: url('../assets/font/Rubik-Regular.ttf')
}
@font-face {
  font-family: AppFontBold;
  src: url('../assets/font/font_Brandon_blk.otf')
}
@font-face {
  font-family: AppFontLight;
  src: url('../assets/font/Rubik-Light.ttf')
}
@font-face {
  font-family: AppFontSemiBold;
  src: url('../assets/font/Rubik-SemiBold.ttf')
}

// app-home,
// app-home > ion-content > main {
//   background: var(--ion-color-primary) !important;
// }
